@import '../variables'
@import '../theme'

.main-menu-container
  // padding: ms(1) ms(4)
  box-sizing: border-box
  z-index: 2
  position: relative

  background-color: $color_purple_darken
  box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.37)

  @include respond-to(small)
    // padding: ms(0)

  a, .item
    color: $color_white
    text-decoration: none
    cursor: pointer
    padding: ms(0) ms(0)
    display: inline-block
    font-weight: 400
    font-size: 18px

    &.new-order
      font-weight: bold
      color: #53b6ff
      border-bottom: 4px solid #53b6ff

    @include respond-to(small-and-medium)
      font-size: 16px

      &.new-order
        border-bottom: none


  ul.menu
    display: flex
    justify-content: center

    @include respond-to(small-and-medium)
      flex-flow: column


  .item
    // letter-spacing: 1px
    // border-right: 1px solid #927692

    // &:last-child
    //   border-right: none

    &.-expandable
      // &:after
      //   position: relative
      //   width: 0
      //   height: 0
      //   content: ""
      //   cursor: pointer
      //   top: 0px
      //   left: 5px
      //   border-left: 5px solid transparent
      //   border-right: 5px solid transparent
      //   border-top: 8px solid #f2e0f0
      //   display: inline-block

  a.item
    height: 100%

  .find-your-tour-button
    font-weight: 400
    color: #FDD3FF

  .menu
    padding-left: ms(2)
    position: relative
    // display: flex
    // justify-content: flex-start
    // align-items: center

    .item
      &:hover, &.active
        background-color: $color_purple

  .menu > li
    display: inline-block
    margin-left: -4px

    &:first-child


  // new menu type
  .menu-content
    position: absolute
    display: none
    width: 100%
    z-index: 999999

    &.active
      display: block

  .sub-menu
    background: $color_white
    display: block
    padding: ms(-2) ms(1)
    width: 100%
    box-sizing: border-box
    box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.37)

    .title
      margin-top: ms(-5)
      // font-size: ms(0) !important
      color: $color_purple_darken200
      display: inline-block
      font-weight: bold
      font-family: $font_secondary

      &.second-title
        // margin-top: ms(0)

    ul
      margin-top: ms(-4)

    ul.long-text-list
      font-size: ms(-1)

    .menu-tours-list
      display: inline-flex
      width: 100%
      flex-flow: row wrap
      justify-content: flex-start
      align-items: flex-start

      @include respond-to(small-and-medium)
        flex-flow: column nowrap

      .tour
        margin-right: ms(-2)
        font-size: ms(1)
        position: relative
        max-width: 30%
        min-width: 30%

        display: inline-flex
        justify-content: flex-start
        align-items: center

        @include respond-to(small-and-medium)
          max-width: 100%
          width: 100%

        .description
          font-size: ms(-1)
          color: #d7d7d7

        .tour-logo
          max-width: 50px
          border-radius: 100%
          margin-right: ms(-2)

        &.ui-bold
          a
            font-weight: bold
            // color: $color_bg

    .country-list
      display: inline-flex
      width: 100%
      flex-flow: row wrap
      justify-content: flex-start
      align-items: flex-start

      .country
        margin-right: 1em
        font-size: ms(2)
        position: relative

        a
          display: inline-flex
          justify-content: center
          align-items: center

      .country-counter
        font-size: ms(-1)
        position: relative
        top: -6px
        color: $color_purple

    // &.one-column
    //   width: 250px

    // &.two-column
    //   width: 500px

    &.three-column
      font-size: ms(1)

    li
      display: block
      list-style: none

      &:first-child
        margin-top: 0

      .description
        font-size: ms(-1)
        color: silver

    a
      display: inline-block
      text-decoration: none
      padding: ms(-3) 0 !important
      font-size: ms(-1)
      width: 100%
      color: #4b3557

      .active, &:hover
        font-weight: 500
        color: $color_purple
        text-decoration: underline

      &:first-child
        margin-top: 0

    .flag
      margin-right: 5px
      width: 16px

    .counter
      display: none // NOTE:
      color: #E0FFBB
      font-weight: 500
      font-size: ms(-1)
      position: relative
      top: - ms(-4)

  .sub-menu.active
    display: block

  .expand-on-mobile-button
    display: inline-block
    margin-left: 3px

    &:before
      content: '\25BC'
      color: #ffde91
      font-size: ms(-2)

    &.active
      transform: rotate(180deg)

  @include respond-to(small-and-medium)
    .menu
      padding-left: 0

    .menu > li
      display: block

      &:first-child

      a, .item
        display: block

    .sub-menu
      position: relative
      width: auto !important
      padding: ms(0)

      li

        a
          display: block
          margin-top: 0
          padding: ms(-2) 0
          font-weight: 300

      .title
        display: block

      .pure-u-1
        margin-top: ms(1)

        &:first-child
          margin-top: 0


    .facebook-share-wrapper
      margin-top: ms(1) !important
