$font_primary: 'PT Sans', sans-serif
$font_secondary: 'Fira Sans', sans-serif

$color_bg: #EEE8F2
$color_box_shadow: #D9D9D9
$color_gold: rgba(255, 223, 193, 1)
$color_gold100: #ffe3dj
$color_sandy: #d9cec3
$color_paper: #F8F4F3
$color_white: white
$color_purple: rgb(97, 25, 102)
$color_purple_darken: rgba(75, 22, 79, 1)
$color_purple_darken200: rgba(43, 3, 42, 1)
$color_purple_light: rgb(149, 91, 189)
$color_purple_light200: #8B3FA9

$color_blue: #285aee
$color_blue100: #3A4DC3
$color_blue_darken: #203977

$color_accent_percent: 5%
