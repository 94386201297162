@import '../variables'
@import '../mixins'
@import '../theme'

.Contacts
  .FloatButton
    position: fixed
    color: #f4f8ff
    right: ms(1) // backward
    bottom: 0
    background: #55a7ff
    padding: ms(-1) ms(-2)
    display: flex
    justify-content: flex-start
    align-items: center
    cursor: pointer
    box-shadow: 0px 5px 8px $color_box_shadow
    font-size: ms(0)
    border-top-left-radius: 5px
    border-top-right-radius: 5px
    z-index: 99
    font-size: 16px
    font-family: $font_secondary
    text-shadow: 0px 1px 8px #535353

    &:hover, &.active
      background: darken(#285aee, 10%)

    .text
      text-align: left
      padding-left: ms(-6)
      // font-weight: bold

    .icon
      width: ms(3)

  .Widget
    position: fixed
    color: black
    right: ms(2)
    bottom: ms(9)
    background: $color_paper
    padding: ms(2) ms(1)
    box-shadow: 0px 5px 8px $color_box_shadow
    font-size: ms(-1)
    z-index: 99
    display: none
    text-align: center
    max-width: 420px

    &.active
      display: block

    hr
      height: 0px
      color: $color_white

    img.close
      position: absolute
      right: ms(-2)
      top: 10px
      cursor: pointer
      width: ms(4)

      &:hover
        top: 11px

    .call-us
      font-family: $font_secondary
      font-size: ms(3)
      font-weight: 700
      color: #8956c8

    .call-us.social
      padding-top: ms(-4)
      color: #285aee

    .phones
      .first, .second
        color: $color_blue_darken
        font-size: 19px
        font-weight: bold

    .schedule
      margin-top: ms(-2)
      color: #4D4D4D

    .recall-text
      font-size: ms(1)
      color: $color_purple_light200

    input#contacts_form_phone_number, input#contacts_form_description
      margin-top: ms(-2)
      border-color: $color_purple_light200
      border-radius: 6px
      max-width: 80%
      min-width: 70%
      padding: ms(-2)
      color: $color_purple_light200
      font-weight: 400
      font-size: ms(1)

    button
      cursor: pointer
      margin-top: ms(-2)
      padding: ms(-2) ms(4)
      background-color: $color_purple_light200
      color: white
      font-weight: 400
      font-size: ms(1)
      border: 0
      border-radius: 6px
      margin-bottom: ms(1)

      &:disabled
        cursor: default
        background-color: #cbadf0

    .social-text
      font-weight: 400
      color: $color_blue

    .social-address
      margin-top: ms(-4)
      font-size: 18px
      font-family: $font_secondary
      font-weight: bold

      a
        color: #2a65d7

    .vk-button
      cursor: pointer
      margin-top: ms(-2)
      padding: ms(-2) ms(4)
      background-color: $color_blue
      color: white
      font-weight: 400
      font-size: ms(1)
      border: 0
      border-radius: 6px
      display: inline-block
      text-decoration: none

    .notification
      padding: ms(2)
      font-size: ms(1)
      font-weight: bold

      a
        color: #2a65d7

      .success
        color: #349158

      .failure
        color: #ff1b1b
