@import '../variables'
@import '../mixins'
@import '../theme'
@import '../srectangle'

.EuroTours
  background-color: #F7F2EE
  padding: ms(0) ms(4)

  .title
    margin-top: ms(-2)
    font-family: $font_secondary
    font-weight: bold
    font-size: ms(4)
    color: #6C1276
    border-bottom: 1px solid #dcc6d7
    padding-bottom: 18px
    text-align: center

  .Results
    margin-top: ms(2)

  .no-results
    text-align: center
    margin-top: ms(2)
    font-weight: bold
    font-size: ms(2)
    color: #393939

    a
      color: #E96AD8

.SearchForm
  margin-top: ms(0)
  padding-top: ms(3)

  .Selectors
    background-color: white
    border-radius: 18px
    box-sizing: border-box
    padding: ms(0)

    label
      font-family: $font_secondary
      color: #B8009E
      display: inline-block
      font-weight: bold

    select
      // margin-top: ms(-1)
      max-width: ms(12)
      // border-radius: 22px
      border: none
      border-bottom: 1px solid #f7dbf0
      background: white
      font-family: $font_secondary
      color: #B8009E
      font-weight: 400
      padding-bottom: ms(-4)
      width: ms(11)

      @include respond-to(medium)
        max-width: 140px

      @include respond-to(small)
        max-width: 90px
        width: auto

    a.reset-search
      margin-top: ms(2)
      font-size: 15px
      display: block
      font-family: $font_secondary
      font-weight: normal
      color: #6C1276

      @include respond-to(small-and-medium)
        text-align: center

.TopPaginationWrapper
  display: flex
  justify-content: end
  align-items: center
  height: 100%

  @include respond-to(small-and-medium)
    margin-top: ms(-4)
    margin-bottom: ms(0)

.ResultsPaginationWrapper
  display: flex
  justify-content: center
  align-items: center
  margin-top: ms(5)
  margin-bottom: ms(2)

  &:first-child
    margin-top: ms(6)
    margin-bottom: none

.Pagination
  font-family: $font_secondary
  // margin-right: ms(0)
  display: inline-block
  text-align: center

  .description
    max-width: 450px
    text-align: center
    color: #6d6895

  a
    padding: ms(-3) ms(0)
    border-radius: ms(-4)
    margin-right: ms(-2)
    background-color: white
    font-size: ms(0)
    color: #B8009E
    text-decoration: none
    display: inline-block

    &:hover
      background-color: $color_paper

    &.active
      font-weight: bold

tours-search-form
  display: block

.tours-page-container
  .ToursList
    margin-top: ms(0)

    .CompactTourHeader
      margin-top: ms(6)

      &:first-child
        margin-top: 0

.EuroTours
  border-bottom: 1px solid #dbdbdb

.EuroTours .ToursCards
  // margin-top: ms(1)
    background-color: #F7F2EE

.ToursCards
  @include respond-to(small-and-medium)
    justify-content: center

.ToursCards .Tour .tour-container
  background-color: $color_white
  padding-bottom: ms(2)
  border-radius: ms(0)
  overflow: hidden
  box-shadow: 0px 18px 26px #E8E2E9

  &:hover
    box-shadow: 0px 18px 26px rgba(130, 130, 130, 0.22)

.ToursCards .Tour
  margin-top: ms(1)
  box-sizing: border-box
  padding-right: ms(-2)
  max-width: 330px

  @include respond-to(large)
    &:nth-child(4n)
      padding-right: 0

  // &:first-child
  //   margin-top: 0

  .logo-container
    display: flex

    img
      width: 100%
      height: 280px
      object-fit: cover

    a
      width: 100%

  .info-container
    padding: 0 ms(0)

  .title
    display: block
    font-weight: bold
    font-size: ms(1)
    font-family: $font_secondary

    a
      color: #643769

      &:hover
        color: lighten(#643769, 10%)

    @include respond-to(small-and-medium)
      margin-top: ms(0)

  .fields-oneline
    // border-left: 1px solid #d7bbd9
    // padding-left: ms(-5)

  .field
    margin-top: ms(-4)
    color: #615664

  .complexities-list
    li
      list-style: none

  .countries-list
    margin-top: ms(0)
    display: inline-block

    img
      max-width: ms(1)

    li
      display: inline-block
      margin-top: 0
      margin-right: ms(-2)
      font-size: 16px

      &:after
        content: ','

      &:last-child
        margin-right: 0

      &:after
        content: none

      a.search-url
        text-decoration: none
        display: inline-block
        border-bottom: 1px dashed silver
        color: #285aee

  .cost
    font-size: ms(1)
    font-family: $font_secondary
    font-weight: 600
    color: #AA1C87

  .duration
    color: #9c69a2

  .start-dates
    color: #5f87ff

  .level
    list-style: none

    a
      color: #615664
      text-decoration: none

  .types-list
    list-style: none
    font-size: ms(-1)

    li
      margin-right: ms(-4)
      display: inline-block

    a
      color: #5C8000
      text-decoration: none

  .buttons-container
    margin-top: ms(2)
    font-weight: 600
    font-size: ms(0)
    font-family: $font_secondary

    a
      display: inline-block
      margin-top: ms(-4)

      &.info
        padding: ms(0)
        border-radius: 6px
        background-color: #995F9D
        color: white
        text-decoration: none
        text-align: center
        width: 100%
        box-shadow: 0px 3px 11px rgba(107, 138, 153, 0.5)

        &:hover
          background-color: darken(#995F9D, 10%)

      &.order
        font-weight: 400
        color: #84C34A
        text-align: center
        width: 100%

.RussianTours
  padding: ms(0) ms(4)
  padding-bottom: ms(8)
  background: linear-gradient(90deg, #F8EBFF 0%, #DBEEFF 100%)
  border-bottom: 1px solid #acacac

  .Results
    margin-top: ms(2)

  .title
    margin-top: ms(1)
    display: flex
    align-items: center
    font-family: $font_secondary
    font-weight: bold
    font-size: ms(5)
    color: #5C1466
    border-bottom: 1px solid #D8BDD4
    padding-bottom: ms(-6)

    img
      width: ms(-2)
      margin-right: ms(-7)
      margin-left: ms(-7)

  .benefits-title
    font-family: $font_secondary
    font-weight: bold
    font-size: ms(3)
    color: #6E4373
    margin-top: ms(2)
    margin-bottom: ms(1)
    padding-bottom: ms(-4)
    border-bottom: 1px solid #D8BDD4

  .Benefits
    .block
      font-size: 15px
      margin-bottom: 38px
      margin-right: 22px
      color: #5E5E5E
      font-weight: 100

    .right-block
      text-align: center

    .button-block
      margin-top: ms(5)
      text-align: center

      a
        border: 2px solid #C6B59F
        border-radius: 28px
        padding: ms(0) ms(3)
        font-family: $font_secondary
        color: #545151
        font-weight: bold
        text-decoration: none

        &:hover
          color: darken(#545151, 10%)
          border: 2px solid darken(#C6B59F, 10%)

    .floating-container
      margin: 0 - ms(4)
      margin-top: ms(2)
      background: #FFFDFA
      padding: ms(4)
      text-align: center
      box-shadow: 0px 16px 19px -14px rgba(0, 0, 0, 0.25)

      .swiper-pagination
        .swiper-pagination-bullet
          width: 12px
          height: 12px
          background-color: #fff7eb
          opacity: 0.8
          box-shadow: 1px 3px 5px #444242

        .swiper-pagination-bullet-active
          background-color: #6fb4ff

      img
        height: ms(14)
        object-fit: cover
        margin-right: 1px

      .bottom

      .description
        font-size: 15px
        max-width: 60%
        display: block
        color: #535153
        padding-left: ms(2)
        margin: auto
        font-weight: 100

.RussianTours .ToursCards
  .tour-container
    box-shadow: 0px 18px 26px rgba(197, 197, 197, 0.38)

    &:hover
      box-shadow: 0px 18px 26px rgba(130, 130, 130, 0.32)

.NewPromotedTours
  padding: ms(4) ms(2)
  background-color: #795983

  @include respond-to(small)
    padding: ms(4) ms(2)
    padding-bottom: ms(6)

  .all-our-tours-button-container
    margin-top: ms(4)
    text-align: center

    a
      position: relative
      padding: ms(-4) ms(3)

      font-size: ms(1)
      color: #F0E1FD
      border: 1px solid #BAADBB
      border-radius: 16px
      text-decoration: none

      &:hover
        color: white
        border-color: white

      .counter
        font-size: ms(-1)
        color: #c98cfd
        position: absolute
        top: 3px
        right: 10px
        font-weight: bold

  .tours-container
    margin-top: ms(2)

    display: flex
    flex-flow: row wrap
    justify-content: space-between
    align-items: baseline

  .title
    text-align: center
    font-weight: bold
    color: white

  // > .pure-g
  //   div[class*="pure-u"]

  //     &:last-child
  //       @include respond-to(medium)
  //         width: 100%


  .tour
    margin: ms(0) ms(-1)
    box-sizing: border-box
    border-radius: 3px
    overflow: hidden
    box-shadow: 0px 2px 4px #251A29


    .logo-container
      position: relative

      .logo
        width: 100%

    .cost
      position: absolute
      bottom: 0
      box-sizing: border-box
      text-align: right

      padding: ms(0)
      background-color: rgba(0, 0, 0, 0.32)
      width: 100%
      color: white
      font-weight: 500
      font-size: ms(1)

    .information-container
      padding: ms(0)
      background: linear-gradient(to top, #F4F4EC, #ECF4DF)
      position: relative
      box-shadow: inset 0px 1px 8px rgba(0, 0, 0, 0.1)

      a.title-url
        margin-top: ms(0)
        display: block
        font-weight: 500
        font-size: ms(1)
        color: #8B799C
        line-height: ms(1)
        text-decoration: none

        &:hover
          color: darken(#684D80, 5%)

      .tour-countries
        font-weight: 300
        font-size: ms(-1)

        a
          color: #818B73
          text-decoration: none
          border: 1px solid #DCD4E0
          padding: ms(-4) ms(-1)
          border-radius: 5px

          &:hover
            color: darken(#818B73, 10%)
            border: 1px solid darken(#DCD4E0, 10%)

      .tour-types
        margin-top: ms(0)
        color: #999
        font-weight: 300
        font-size: ms(-1)

        li
          list-style: none

    .big-button
      display: block
      text-align: center
      padding: ms(0)
      box-sizing: border-box
      text-decoration: none
      color: #292929
      text-transform: uppercase
      font-weight: bold
      font-size: ms(-1)

      background: linear-gradient(to top, #A9CD62, #B4D377)

      &:hover
        background: darken(#A9CD62, 5%)

// .NewPromotedTours

.RussianTours, .EuroTours
  a.all-tours
    padding: ms(0) ms(2)
    border-radius: 53px
    background: linear-gradient(#a485f2, #955A99)
    font-weight: 500
    font-family: $font_secondary
    font-size: 20px
    font-weight: 500
    color: white
    text-decoration: none
    text-align: center
    width: auto
    box-shadow: 0px 2px 1px rgba(174, 174, 174, 0.76)
    margin-top: ms(1)
    margin-bottom: ms(1)
    display: inline-block

    &:hover
      background: linear-gradient(darken(#a485f2, 7%), #9B1FA4)
      box-shadow: 0px 4px 5px rgba(83, 108, 119, 0.5)

