@import '../variables'
@import '../mixins'
@import '../theme'

.Header
  min-height: 450px
  // background: linear-gradient(rgba(47, 8, 53, 0.88), rgba(129, 64, 138, 0.51))
  background-size: cover
  color: $color_white
  padding: ms(0) ms(1)

  background-repeat: no-repeat
  animation: slideshow 45s ease-in-out infinite

  @keyframes slideshow
    0%
      background-image: linear-gradient(rgba(47, 8, 53, 0.88), rgba(129, 64, 138, 0.51)), url('../images/header_new/velotravel.png')
    15%
      background-image: linear-gradient(rgba(47, 8, 53, 0.88), rgba(129, 64, 138, 0.51)), url('../images/header_new/01.jpg')
    30%
      background-image: linear-gradient(rgba(47, 8, 53, 0.88), rgba(129, 64, 138, 0.51)), url('../images/header_new/03.jpg')
    45%
      background-image: linear-gradient(rgba(47, 8, 53, 0.88), rgba(129, 64, 138, 0.51)), url('../images/header_new/05.jpg')
    60%
      background-image: linear-gradient(rgba(47, 8, 53, 0.88), rgba(129, 64, 138, 0.51)), url('../images/header_new/06.jpg')
    100%
      background-image: linear-gradient(rgba(47, 8, 53, 0.88), rgba(129, 64, 138, 0.51)), url('../images/header_new/07.jpg')

  .left

  .right
    padding-top: ms(2)
    text-align: right

  .company
    display: flex
    align-items: center
    align-content: flex-start

  .name-container
    padding-left: ms(-1)

  .name
    a
      font-size: ms(4)
      font-weight: 700
      color: $color_white

      @include respond-to(small-and-medium)
        font-size: ms(3)

    .subline
      margin-top: ms(-2)
      color: $color_gold

  .phones
    display: flex
    justify-content: flex-end
    align-items: center
    font-weight: 500

    .icon img
      @include respond-to(small-and-medium)
        width: ms(6)

    .second
      color: $color_sandy

    @include respond-to(small-and-medium)
      font-size: ms(-1)

    .list
      margin-left: ms(-2)

  .schedule
    color: $color_gold

  .slogan-cnt
    margin-top: ms(1)
    width: 100%
    text-align: center
    font-family: $font_secondary

    a
      color: white
      text-decoration: none

    .slogan
      margin-top: ms(0)
      font-size: ms(6)
      font-weight: 700

      @include respond-to(small-and-medium)
        font-size: ms(4)

    .second
      font-size: ms(3)
      font-weight: 400

      @include respond-to(small-and-medium)
        font-size: ms(2)

    .third
      margin-top: ms(1)
      font-size: ms(1)
      font-weight: 300
      display: inline-block
      padding: 0 ms(2)
      color: $color_gold100

      &::before
        display: inline-block
        position: absolute
        left: 47%
        width: ms(4)
        height: 1px
        content: ""
        background: $color_white
        margin-top: - ms(-4)

      @include respond-to(small)
        padding: ms(-1)

