@import 'select2/src/scss/core'
@import 'pickadate/lib/themes/default'
@import 'pickadate/lib/themes/default.date'
@import 'swiper/dist/css/swiper'
@import 'animate.css'
@import 'fancybox'
@import 'purecss/build/base'
@import 'purecss/build/grids'
@import 'purecss/build/grids-responsive'

@import './application/variables'
@import './application/theme'
@import './application/mixins'
@import './application/instagram'

// General rules:
body
  background-color: $color_bg

body, html
  font-family: $font_primary

  @include respond-to(small)
    font-size: 14px

  @include respond-to(medium)
    font-size: 15px

  @include respond-to(large)
    font-size: 16px

h1, h2, h3, h4, h5, h6
  font-weight: 300
  line-height: ms(1)
  margin: 0 0 0 0

h1
  font-size: ms(5)

h2
  font-size: ms(4)

h3
  font-size: ms(3)

h4
  font-size: ms(2)

h5
  font-size: ms(1)

p
  font-size: ms(0)
  line-height: ms(1)
  margin: 0 0 0 0

div, span, label, ul
  font-size: ms(0)
  line-height: ms(1)
  margin: 0 0 0 0
  padding: 0 0 0 0

.pure-g [class *= "pure-u"]
  font-family: $font_primary

// Components:

.admin-block
  margin: 0 auto
  position: relative
  min-width: 300px
  max-width: 1200px
  width: 100%
  overflow: hidden

  box-sizing: border-box

  text-align: left

  padding: ms(1)

  a
    font-size: ms(-1)
    color: #685b68

#page-container
  width: 100%
  // margin-top: ms(1)
  margin-bottom: ms(1)

.layout-container
  margin: 0 auto
  position: relative
  min-width: 300px
  max-width: 1200px
  width: 100%
  overflow: hidden

  box-sizing: border-box

  box-shadow: 0px 0px 10px 2px $color_box_shadow
  // border-right: 1px solid #6A556F
  // border-left: 1px solid #6A556F

  background-color: white

// .layout-container > .header-container
//   height: ms(14)

//   .swiper-container
//     position: absolute
//     max-width: 100%
//     box-sizing: border-box

//     .swiper-pagination
//       position: relative
//       top: -130px
//       z-index: 2

//       .swiper-pagination-bullet
//         background: white
//         width: 10px
//         height: 10px

//       // .swiper-pagination-bullet-active
//       //   background: #B5C88D
//       //   width: 10px
//       //   height: 10px

//   // background: url('header/01.png') #B5C88D no-repeat left center

//   // @include respond-to(small)
//   //   background: url('header/01.png') #B5C88D no-repeat 45%
//   //   padding: ms(2)

//   > .top-line-container
//     padding: ms(0)
//     display: flex
//     position: relative
//     z-index: 2
//     background-color: rgba(67, 44, 70, 0.75)

//     span
//       margin: auto
//       // font-weight: 500
//       // font-size: ms(0)
//       color: #EEEEEE

//     a
//       color: #FBC8F5

//   > .slogan-container
//     margin-top: ms(6)
//     text-align: center
//     z-index: 2
//     position: relative

//     .first-line, .second-line
//       color: white

//       a
//         color: white
//         text-decoration: none

//     .first-line
//       font-size: ms(6)
//       font-weight: bold
//       text-shadow: 0px 1px 53px #281A2B

//       @include respond-to(small-and-medium)
//         font-size: ms(4)

//     .second-line
//       display: inline-block

//       font-size: ms(2)
//       font-weight: 400

//       color: #F3D9F5
//       text-shadow: 0px 0px 10px rgb(39, 23, 49)

//       @include respond-to(small-and-medium)
//         font-size: ms(1)


//     .second-line.with-main-target
//       .main-target
//         // border-bottom: 1px dashed #AFAFAF
//         // display: inline-block
//         cursor: pointer

// // .layout-container > .header-container

.layout-container > .our-benefits-container
  display: block
  background-color: white
  padding: ms(2) ms(0) ms(6) ms(0)

  > .title
    text-align: center
    font-weight: bold
    font-family: $font_secondary
    color: #444D37

    @include respond-to(small)
      font-size: ms(2)

  .arrow
    cursor: pointer

  .menu
    display: flex
    flex-flow: row wrap
    justify-content: space-between
    font-size: 17px

    li
      display: inline-block
      // margin-right: ms(-2)
      border: 1px solid #F2E4FD
      border-radius: 5px
      margin-top: ms(-5)

      &:hover
        border-color: darken(#F2E4FD, 20%)

      @include respond-to(small-and-medium)
        display: block
        width: 100%

      a
        display: inline-block
        padding: ms(-2) ms(0)
        text-decoration: none
        color: #836B98
        font-weight: 500

        @include respond-to(small-and-medium)
          display: block

    li.active
      background-color: #F1DDFF

      a
        color: black

  .content
    font-size: 17px
    color: #35132d

    p
      margin-top: ms(0)

    ul

    li
      list-style: none
      list-style-position: inside
      margin-top: ms(0)

    li.critical
      font-weight: 500
      color: #8C3477

    .title
      font-size: ms(1)
      margin-top: ms(0)
      font-weight: bold

    .tour-guide-example-url
      display: block
      margin-top: ms(0)
      color: #AA1D87
      font-weight: bold
      font-size: ms(1)

  .arrow-container
    text-align: center
    margin-top: ms(3)
    // display: flex
    // justify-content: center
    // align-items: center

    @include respond-to(small)
      display: none

  .content-container
    margin-top: ms(2)

  .content
    margin-top: ms(2)
    font-weight: 400
    color: #2A2A2A

  @include respond-to(small)
    .arrow-container
      margin-top: ms(5)

      img
        width: ms(0)

// .layout-container > .our-benefits-container

.layout-container > .what-is-velotour-comprasion-container
  padding: ms(0) ms(4) ms(0) ms(4)
  background-color: rgb(252, 248, 255)
  // box-shadow: inset 0px 0px 7px $color_box_shadow

  @include respond-to(small)
    padding: ms(2)

  > .title
    text-align: center
    font-weight: bold
    color: black

    @include respond-to(small)
      font-size: ms(3)

  .short-description
    margin-top: ms(2)
    text-align: center
    padding: ms(-2)

    font-weight: 300
    font-size: ms(1)
    color: #F8E4FF
    background-color: rgba(0, 0, 0, 0.26)

    @include respond-to(small)
      font-size: ms(0)

  .compare-grid-container

  .compare-element-container
    position: relative

    .title
      margin: 0 ms(2)
      padding: ms(0)
      font-size: ms(3)
      text-align: center
      font-family: $font_secondary
      font-weight: bold
      color: #6D536C

    &.left
      .title

      @include respond-to(small-and-medium)
        margin-right: 0

    &.right
      .title
        // color: #646464

      @include respond-to(small-and-medium)
        margin-left: 0

    li
      margin-top: ms(0)
      color: #4F4F4F
      list-style: none
      font-weight: 300

      display: flex
      justify-content: flex-start
      align-items: center

      &:first-child
        margin-top: 0

      img.icon
        width: ms(5)
        padding-right: ms(-1)

    .description-container
      padding: 0 ms(2)
      padding-bottom: ms(3)

  .next-action-container
    font-size: ms(1)
    color: black

    .online-request
      background-color: rgba(182, 122, 251, 0.49)
      padding: ms(-4)

      a
        color: black

    .move-to-next
      a
        color: #992290

// .layout-container > .what-is-velotour-comprasion-container

.layout-container > .what-is-velotour-description-container
  text-align: center
  padding: ms(4) ms(4)

  @include respond-to(small)
    padding: ms(2)

  // border-bottom: 4px solid #CCDE95

  .question
    font-weight: bold
    color: #444d37

  .answer
    margin-top: ms(-2)
    font-weight: 500
    color: #735C78
    font-size: ms(1)

// .layout-container > .what-is-velotour-description-container

.layout-container > .russian-velotours-container
  .top
    align-items: center
    padding: ms(3)
    padding-top: ms(6)
    background: #69499A
    background: linear-gradient(to right, #6d5099, #7c688d)
    border-top: 2px solid rgb(255, 238, 186)

    // background: -webkit-linear-gradient(left, #69499A, #29E7C5)
    // background: -moz-linear-gradient(left, #69499A, #29E7C5)
    // background: linear-gradient(to right, #69499A, #29E7C5)

    .head-part
      padding-left: ms(1)

    .body-part
      .first-line
        a
          color: white
          font-size: ms(3)
          font-weight: bold
          font-family: $font_secondary
          line-height: ms(0)

          &:hover
            color: rgb(251, 191, 255)

      .second-line
        margin-top: ms(-3)
        color: rgba(255, 220, 96, 1)
        font-size: ms(2)
        font-weight: 300

        strong
          font-weight: 500

  .banner-content
    color: black
    padding: ms(5)
    background: #FEFFD1
    background: linear-gradient(to top, #ffe5fe, #fdfffc)
    // background: -webkit-linear-gradient(bottom, #FEFFD1, #C9FFFC)
    // background: -moz-linear-gradient(bottom, #FEFFD1, #C9FFFC)
    // background: linear-gradient(to top, #FEFFD1, #C9FFFC)

    .left-part
      display: flex
      flex-direction: column
      justify-content: flex-start
      padding-right: ms(2)

    .right-part
      padding-right: ms(0)
      display: flex
      align-items: center

      img
        max-width: 100%

        @include respond-to(small)
          margin-top: ms(4)

    .intro
      font-weight: bold
      font-size: ms(1)

    .main-text
      margin-top: ms(1)
      font-size: ms(0)

      ul
        li
          list-style: none
          padding-bottom: ms(0)

    .bottom-part
      margin-top: ms(4)
      text-align: center

      .main-button
        background: rgba(169, 100, 145, 1)
        padding: ms(-2) ms(4)
        font-weight: bold
        color: white
        border-radius: 5px

        &:hover
          background: darken(rgba(169, 100, 145, 1), 5%)


      .second-button
        margin-top: ms(1)
        display: block
        color: rgba(82, 67, 89, 1)
        font-weight: 500
        font-size: ms(1)

.layout-container > .main-tour-types-container
  padding: ms(4) ms(2) ms(6) ms(2)
  background-color: #EDF5E5
  border-bottom: 6px solid #E5EFD9

  @include respond-to(small)
    padding: ms(2)

  > .title
    text-align: center
    font-weight: bold
    font-family: $font_secondary
    color: #444D37

    @include respond-to(small)
      font-size: ms(3)

  .compare-grid-container

  .compare-element-container
    position: relative
    padding: ms(2) 0 ms(4) 0
    margin: ms(5) ms(0) 0 ms(0)

    background-color: white
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.14)

    border-top-left-radius: 12px
    border-top-right-radius: 12px

    & > .title
      padding: ms(-5) 0
      text-align: center
      font-size: ms(1)
      font-weight: 500
      font-family: $font_secondary
      background-color: #E3FFC7

    .top-line
      padding: ms(-2)
      margin-top: ms(0)

      text-align: center
      font-weight: 500
      color: #8D6EB1

    .description-container
      padding: ms(2)
      color: #565656

    li
      margin-top: ms(0)
      list-style-position: inside

    li:first-child
      margin-top: 0

  .compare-element-container.is-primary
    background-color: #D0EBB2

    & > .title
      background-color: #90B46C

    .is-primary-icon
      position: absolute
      right: ms(0)
      top: ms(0)

  .logo-container
    height: ms(9)
    text-align: center
    display: flex

    .logo
      margin: auto

  .next-action-container
    font-size: ms(1)
    color: #4F4F4F

    .online-request
      padding: ms(-4)
      background-color: #87BA53

      a
        color: black

    .move-to-next a
      color: #417505


// .layout-container > .main-tour-types-container

.layout-container > .latest-reviews-container
  padding: ms(4) ms(4) ms(6) ms(4)
  background-color: #FEFBFF

  > .title
    font-family: $font_secondary
    text-align: left
    font-weight: bold
    color: #646464

    a
      color: #5181b8

    @include respond-to(small)
      font-size: ms(3)

  > .pure-g
    margin-top: ms(5)

  .submenu
    margin-top: ms(0)

  .all-reviews-button
    width: 100%
    display: block
    text-align: center
    padding: ms(-2)
    font-size: ms(1)
    font-weight: bold
    text-decoration: none
    border-radius: 4px
    background: #80647e
    color: white
    margin-top: ms(1)

    &:hover
      background: darken(#80647e, 10%)

    .counter
      font-weight: normal
      position: relative
      top: -7px
      font-size: 15px
      color: #ffc7f1


  .review
    margin-top: ms(2)

    .title
      font-size: ms(1)
      font-weight: 500

      a
        color: #7C34BB

    .date
      color: #445037

    .photos
      margin-top: ms(0)
      display: flex
      flex-flow: row wrap
      justify-content: space-between

      img
        width: 150px
        height: 150px
        margin-bottom: ms(-2)
        border: 1px solid #E3F7CF

        @include respond-to(small)
          width: 120px
          height: 120px

  .review:first-child
    margin-top: 0

  .menu-container
    padding: 0 ms(3)
    box-sizing: border-box

    .facebook-share-wrapper
      margin-top: ms(0)

    @include respond-to(small-and-medium)
      margin-top: ms(3)
      justify-content: flex-start

    li
      margin-top: ms(-4)
      list-style: none
      padding: ms(-4)

      a
        color: #458000

    li.is-primary
      background-color: #E3F7CF

      a
        color: #7737BA

    li:first-child
      margin-top: 0

    .counter
      font-weight: bold
      position: relative
      top: -5px

// .layout-container > .latest-reviews-container

.layout-container > .veloship-container
  padding: ms(3)
  padding-bottom: ms(5)
  color: #55415b
  background: linear-gradient(90deg, #fbf8ef 0%, #fdfeff 61%, #effaff 100%)

  a
    color: #7c34bc
    text-decoration: none
    border-bottom: 1px dashed #7c34bd

    &:hover
      color: #588303

  .title
    font-family: $font_secondary
    font-weight: bold
    color: #4e3e52

    &:hover
      color: #588303

  .intro-text
    margin-top: ms(-2)
    font-size: ms(1)

  img.image
    width: 100%
    margin-top: ms(1)
    // margin: ms(2)

.layout-container > .all-tour-types-container
  padding: ms(4) ms(4) ms(6) ms(4)
  background: url('../images/icons/bike.png') #3a412d no-repeat right bottom

  @include respond-to(small)
    padding: ms(4)

  > .title
    font-family: $font_secondary
    text-align: center
    font-weight: bold
    color: white

    @include respond-to(small)
      font-size: ms(3)

  .icon
    width: 60px

  ul, .grid-container
    margin-top: ms(5)

  li
    list-style: none
    margin-top: ms(2)

    display: flex
    justify-content: flex-start
    align-items: center

    &:first-child
      margin-top: 0

  .element
    margin-top: ms(2)

    display: flex
    justify-content: flex-start
    align-items: center

    // &:first-child
    //   margin-top: 0

  .info
    margin-left: ms(0)
    margin-right: ms(0)

    .title
      a
        color: #F8B5FF
        font-size: ms(1)

    .description
      color: white
      margin-top: ms(-2)
      // font-weight: 300
      font-size: 0.9rem


// .layout-container > .all-tour-types-container

.layout-container > .all-countries-container
  padding: ms(4) ms(4) ms(6) ms(4)
  background: url('../images/icons/earth.png') #FDFFFA no-repeat right bottom

  > .title
    text-align: center
    font-family: $font_secondary
    font-weight: bold
    color: #4B4B4B

    @include respond-to(small)
      font-size: ms(3)

  > .pure-g
    margin-top: ms(6)

  li
    list-style: none
    margin-top: ms(0)

    &:first-child
      margin-top: 0

    a
      color: #888888
      font-size: ms(1)
      text-decoration: none

      &:hover
        color: #4E7520

// .layout-container > .all-countries-container

.layout-container > .cool-numbers-container
  padding: ms(2) ms(4)
  background: linear-gradient(to top, #ffffff, #ece0ef)
  box-shadow: inset 0px 0px 5px rgba(144, 144, 144, 0.3)

  @include respond-to(small)
    padding: ms(2)

  ul
    li
      // margin-top: ms(-2)
      list-style: none
      display: flex
      justify-content: center
      align-items: center


    li:first-child
      margin-top: 0

  .number
    font-size: ms(0)
    font-weight: 500
    color: #693f75

  .text
    margin-left: ms(-5)
    font-size: ms(0)
    font-weight: 400
    color: #82598E

    @include respond-to(small)
      font-weight: normal
      font-size: ms(0)

// .layout-container > .cool-numbers-container

.page-content-container
  background-color: white
  padding: ms(4) ms(4) ms(6) ms(4)

  img
    max-width: 100%

  h1, h2
    font-size: ms(4)
    font-weight: bold
    color: #715A78
    font-family: $font_secondary

    margin-top: ms(1)

    &:first-child
      margin-top: 0

  h3
    font-size: ms(3)
    font-weight: 500
    margin-top: ms(1)

    color: #715A78

    // &:first-child
    //   margin-top: 0

  h4, h5, h5
    font-size: ms(2)
    font-weight: 500

    color: #715A78

    margin-top: ms(1)

    // &:first-child
    //   margin-top: 0

  p, ul, table
    margin-top: ms(2)

  ul, table, li
    line-height: ms(1)

  p
    line-height: ms(0)

  li
    margin-top: ms(0)

  td
    padding-top: ms(0)
    padding-right: ms(0)

    &:first-child
      padding-top: 0

  .page-content-body
    margin-top: ms(-1)
    color: #464646
    p
      margin-top: ms(1)
      line-height: 26px
      font-size: 18px

      &:first-child
        // margin-top: 0

// .layout-container > .page-content-container

.layout-container > .reviews-page-container

  .reviews-list-container
    margin-top: ms(2)
    margin-bottom: ms(8)

  .countries-menu
    margin-top: 0
    background-color: white

    li
      font-size: ms(1)
      list-style: none
      display: inline-block
      margin-right: ms(-2)

    a
      display: inline-block
      position: relative
      text-decoration: none
      border-bottom: 1px dashed #8A5B97

      .counter
        position: relative
        top: - ms(-4)
        border-bottom: none
        font-weight: 300
        color: #9D68AB
        font-size: ms(0)

// .layout-container > .reviews-page-container

.layout-container > .other-reviews-block
  padding: ms(0) ms(6) ms(6) ms(6)

  .title
    font-weight: bold
    color: #715a78

  .reviews-list-container
    margin-top: ms(6)

// .layout-container > .other-reviews-block

.CompactTourHeader
  box-sizing: border-box

  &:first-child
    margin-top: 0

  .logo-container
    display: flex

    .logo, a

  .title
    display: block

    a
      color: #975CA7
      font-family: $font_secondary
      font-size: ms(1)
      line-height: ms(1)
      font-weight: bold

    @include respond-to(small-and-medium)
      margin-top: ms(0)


  .information-content-container
    margin-top: ms(-6)

    a
      color: #917B9D

    .first-line-contrainer
      // display: inline-block
      margin-top: ms(-2)
      font-weight: bold
      font-size: ms(1)

      .countries-list
        display: inline-block
        font-family: $font_secondary


        li
          display: inline-block
          margin-top: 0
          margin-right: ms(-4)

          .flag
            width: ms(1)
            display: relative
            top: 5px

          &:last-child
            margin-right: 0

            &:after
              content: none

          a.search-url
            text-decoration: none
            display: inline-block
            border-bottom: 1px dashed silver

      .cost
        color: #AA1C87
        font-size: ms(1)
        font-family: $font_secondary

      .start_dates
        font-weight: 400
        color: #808080

        @include respond-to(small-and-medium)
          display: block
          margin-left: 0
          margin-top: ms(-6)

    .fields-container
      margin-top: ms(-4)
      color: #828282
      font-size: ms(1)

      .label
        font-weight: bold
        color: $color_bg
        margin-right: ms(-4)

      a.search-url
        text-decoration: none
        display: inline-block
        border-bottom: 1px dashed silver

      .types-list
        margin-top: 0
        display: inline-block

        li
          display: inline-block
          margin-top: 0
          margin-right: ms(-2)
          list-style: none

          &:after
            content: ','

          &:last-child
            margin-right: 0

            &:after
              content: none

          a.search-url
            text-decoration: none
            display: inline-block
            border-bottom: 1px dashed silver

    .buttons-container
      margin-top: ms(2)
      font-weight: 500

      a
        color: #7E4289
        padding: ms(-3) ms(0)
        text-decoration: none
        border-radius: 2px
        background: linear-gradient(to top, #E2BDE8, #FDC8FF)
        font-size: ms(-1)
        font-weight: bold
        box-shadow: 0px 1px 1px #AEAEAE
        text-transform: uppercase

        &:hover
          background: darken(#E2BDE8, 3%)

      a.show
        color: #6C6969
        background: linear-gradient(to top, #D4F1BF, #E5FCD5)

        &:hover
          background: darken(#D4F1BF, 3%)

      a:first-child
        margin-right: ms(-4)

// .CompactTourHeader

velo-tour
  display: block

.FullTour
  display: block

  p
    @include respond-to(small)
      overflow-x: scroll

  .tab-content
    @include respond-to(small)
      overflow-x: scroll

  img
    // max-width: 100%
    @include respond-to(small)
      overflow-x: scroll

  .CompactTourHeader
    padding: ms(4) ms(6)

  .tabs-container
    z-index: 2
    padding: ms(0) ms(6)
    box-sizing: border-box
    background-color: #FCF6FD
    box-shadow: 0px 2px 2px rgba(160, 160, 160, 0.13)

    @include respond-to(small)
      padding: ms(2) ms(0)

    a
      text-decoration: none
      color: #6F4978

    ul
      margin-top: - ms(0)
      display: flex
      justify-content: space-between
      align-items: center
      flex-flow: row wrap

      @include respond-to(small-and-medium)
        display: block

    li
      display: inline-block
      margin-right: ms(-2)
      margin-top: ms(0)
      padding: ms(-6) ms(0)

      background: linear-gradient(to top, #D4B0D0, #EAC8E6)
      border-bottom: 1px solid #D8B9E1
      border-radius: ms(-8)
      color: #843A88

      &.active, &:hover
        background: darken(#D4B0D0, 2%)
        border-bottom: 1px solid #725C78

      &.online-request
        color: #FFFFFF
        background: linear-gradient(to top, #E96AD8, #F79EE4)
        border-bottom: 1px solid #CC3EB9
        box-shadow: 0px 2px 7px rgba(113, 111, 111, 0.36)

        &:hover
          background: darken(#E96AD8, 2%)

      @include respond-to(small-and-medium)
        display: block
        padding: ms(0)
        margin-right: 0

  .tab-content
    p
      margin-top: ms(-2)
      line-height: ms(2)
      font-size: 18px
      // text-indent: 10px

      &:first-child
        margin-top: 0

    table, ul, li
      font-size: 18px
      line-height: ms(0)

    ul
      margin-top: ms(-2)
      margin-left: 8px

    ul, li
      list-style: initial

    ul:first-child
      margin-top: 0

    table
      margin-top: ms(0)
      margin-bottom: ms(2)
      // font-size: 18px
      font-size: 16px
      width: 100%

    td
      padding: ms(-4)
      border: 1px solid #FCE8FF

  .content-title
    font-family: $font_secondary
    font-size: ms(3)
    font-weight: bold
    margin-bottom: ms(-1)
    margin-top: ms(4)
    color: #a0158d
    border-bottom: 2px solid
    padding-bottom: 12px

    &:first-child
      margin-top: 0

  .tabs-content-container
    padding: ms(4) ms(6) ms(4) ms(6)

    @include respond-to(small)
      padding: ms(2)

    .tour-info-list-container
      margin-bottom: ms(2)
      display: flex
      justify-content: flex-start
      align-items: baseline
      font-size: ms(0)
      font-family: $font_secondary

      @include respond-to(small-and-medium)
        display: block
        flex-direction: column

      h4
        color: #074DB9
        font-size: ms(1)
        font-weight: 500
        background-color: #FEFDFF

      .item-info
        margin-top: ms(-5)
        display: block
        font-size: 17px
        color: #484648

      .item
        margin-top: ms(0)
        margin-right: ms(2)
        width: auto

        @include respond-to(small)
          width: 100%
          display: block

        &:first-child
          margin-top: 0

// .layout-container > .tour-container

.tour-order-container
  // text-align: center
  margin-top: ms(1)

  p
    color: #5d5d5d

  .alert
    margin-top: ms(1)

  .title
    color: #B33992
    font-weight: bold
    font-family: $font_secondary
    font-size: ms(2)
    margin-top: 0
    // border-top: 1px solid #c6c6c6
    padding-top: 0

  .order-form-container
    // text-align: center
    margin-top: ms(1)

    input, textarea, select
      width: 50%
      font-size: ms(1)
      padding: ms(-5)

      @include respond-to(small)
        width: 100%

    label
      display: block
      margin-bottom: ms(-4)

      font-size: ms(1)
      font-weight: 500
      color: #793667

      &.required
        font-weight: bold

    .submit-button
      margin-top: ms(2)
      padding: ms(0)
      cursor: pointer
      background-color: #E96AD8
      border: 0
      border-radius: ms(-6)
      color: white
      border-bottom: 2px solid #B045A1

    .help-block
      color: #E96A91
      display: block

    .form-group
      margin-top: ms(2)

      &:first-child
        margin-top: 0

    .alert
      background-color: #E96A75
      padding: ms(0)
      color: white

      ul, li
        margin: 0

      li
        list-style: none

.reviews-list-container

  .logo-container
    display: flex

    .logo
      margin: auto

    img
      border-radius: 100%
      border: 1px solid #EAFDCC

  .title
    font-size: ms(1)
    font-weight: 500

    a
      color: #9D68AB

    @include respond-to(small-and-medium)
      margin-top: ms(0)

  .review
    margin-top: ms(5)

    &:first-child
      margin-top: 0

  .content-container
    margin-left: ms(1)

    @include respond-to(small-and-medium)
      margin-left: 0

  .read-review-button
    margin-top: ms(0)
    display: inline-block
    font-weight: 500
    color: #9D68AB
    padding: ms(-6)
    background-color: #FCF6FD

  .review-teaser-container
    margin-top: ms(0)
    font-weight: 300


// .reviews-list-container

.upload-images-container
  padding: ms(2) ms(6) ms(-2) ms(6)
  width: 100%
  box-sizing: border-box

  background: linear-gradient(90deg, #F8EBFF 0%, #DBEEFF 100%)

  .swiper-slide
    width: auto !important

    img
      object-fit: cover
      height: ms(13)
      width: ms(11)

  .swiper-container
    width: 100%
    height: 100%

  .swiper-pagination
    position: relative
    margin-top: ms(2)

  .swiper-pagination-bullet
    width: ms(-1)
    height: ms(-1)
    background: #925A8A

  .swiper-pagination-bullet-active
    background: #E96AD8

  img.upload-image
    opacity: 0.98

    &:hover
      opacity: 1

  @include respond-to(small)
    padding: ms(2)

  a
    text-decoration: none

  img

// .upload-images-container

.EnglishToursFaq
  padding: ms(4) ms(4)

  > .title
    // text-align: center
    // font-size: ms(2)
    font-weight: bold
    color: #715a78

.EnglishToursFaq, .FullTour
  .question-container
    margin-top: ms(4)

    &:first-child
      margin-top: 0

  .question
    padding: ms(-4)
    margin-top: ms(1)
    border-bottom: 1px dashed #C4A4CD
    background-color: #FCF8FF
    cursor: pointer
    position: relative
    font-size: 21px
    font-family: $font_secondary
    color: #464646

    &:hover, &.active
      background-color: darken(#FCF8FF, 5%)

    .title
      color: #551A8B
      font-weight: 500
      font-size: ms(2)

    .arrow
      display: inline-block
      font-size: ms(-3)
      position: relative
      top: -3px
      margin-right: ms(-3)

      &:before
        // display: inline-block
        content: '\25BC'
        color: #B996C2

      &.active
        transform: rotate(180deg)

  .answer
    margin-top: ms(0)
    display: none

    &.active
      display: block

    p
      margin-top: ms(0)

      &:first-child
        margin-top: 0

// .EnglishToursFaq



.Footer
  min-height: ms(8)
  background: linear-gradient(to top, #5C455C, #8E6A8E)
  padding: ms(0) ms(4)
  box-shadow: 0px 0px 5px #929292

  .site-domain
    color: #D2C6DD

  .company-name
    font-weight: 300
    color: #D2BFE2
    margin-bottom: ms(2)

  .fb-like

  .menu
    margin-top: - ms(-4)
    margin-bottom: ms(2)

    li
      display: inline-block
      list-style: none

    a
      display: inline-block
      margin-top: ms(-4)
      padding: ms(-7) ms(-2)
      border: 1px solid #949394
      color: #EBDFF5
      border-radius: 4px
      text-decoration: none
      font-weight: 300
      font-size: ms(-1)

      &.-russian-velotour
        color: #E5F1D8

      &:hover
        color: white
        border-color: white

// .Footer

.CompanyMembers
  .header
    background: #ECE5F2
    padding: ms(2) ms(4)

    .title
      color: #725979
      font-weight: bold
      font-size: ms(2)

  .members
    padding: ms(2) ms(4)
    background: #F9F2FF
    box-shadow: 0px 2px 7px #E3E3E3

    display: flex
    justify-content: center
    align-items: center
    flex-flow: row wrap

  .member
    margin-top: ms(2)
    margin-right: ms(2)
    display: inline-block
    max-width: 240px
    text-align: center
    box-sizing: border-box

    .photo
      border-radius: 100%
      width: 150px
      height: 150px

    .name
      margin-top: ms(0)
      font-size: ms(1)
      color: #817C83

      &.-founder
        font-weight: bold

    .role
      margin-top: ms(-2)
      color: #856F8D
      font-weight: 300
      text-align: center

// .CompanyMembers

.FlashMessages
  padding: ms(0) ms(2)
  font-size: ms(1)
  font-weight: 300

  .item
    padding: ms(-2)

    &:nth-child(2)
      margin-top: ms(0)

  .alert
    background-color: #F3C2C2
    color: #4D4D4D

  .notice
    background-color: #D4F1BF
    color: #4D4D4D


// .FlashMessages

// ******** Move to common.sass ********* //

.next-action-container
  margin-top: ms(3)
  text-align: center
  color: white

  a
    color: white

  .online-request
    // padding: ms(-4) ms(0)
    margin-right: ms(-2)

  .second-part
    @include respond-to(small)
      display: block
      margin-top: ms(0)

  .move-to-next
    margin-left: ms(-2)

// .next-action-container


// ******** Move to common.sass ********* //

#scrollUp
  bottom: ms(-2)
  left: ms(2)
  padding: ms(-2)
  background-color: #CE42AB
  color: #fff
  text-decoration: none
  opacity: 0.8

[v-cloak]
  display: none

a.admin-link
  color: black
  font-size: ms(-1)
  font-weight: 100
  float: right
  padding: ms(-3)

@import './application/blocks'
