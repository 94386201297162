@import 'variables';

// Основано на pure.css grid breakpoints
// small            - мобилы
// medium           - планшеты
// large            - все остальное (>=1024px)
// small-and-medium - мобилы + планшеты
// medium-and-large - планшеты + все остальное

@mixin respond-to($media) {
  @if $media == small {
    @media only screen and (max-width: $size_sm) { @content; }
  }

  @if $media == medium {
    @media only screen and (min-width: $size_sm) and (max-width: $size-lg) { @content; }
  }

  @if $media == large {
    @media only screen and (min-width: $size_lg) { @content; }
  }

  @if $media == medium-and-large {
    @media only screen and (min-width: $size_md) { @content; }
  }

  @if $media == small-and-medium {
    @media only screen and (max-width: $size-lg) { @content; }
  }
}
