$size: 7vmin;

*,
*:before,
*:after { box-sizing: border-box; }

.instagram-wrapper {
  text-align: center;
  padding: ms(2) ms(0);
  background: #fff1d3;

  &:hover {
    background: darken(#fff1d3, 5%);
  }

  .main {
    font-size: ms(2);
    font-weight: bold;
  }

  .side {
    color: #9a62cb;
  }
}

.instagram-logo {
  background:
    /////// Use radial gradients to get the rounded
    /////// gradient effect in each corner

    // left bottom
    radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%),
    radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
    // left top
    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),
    //  right top
    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
    radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),
    // right bottom
    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),

    /////// create a base coat to smooth
    /////// corner gradients
    linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);

  border-radius: .2em;
  font-size: $size;
  height: 1em;
  position: relative;
  width: 1em;
  display: inline-block;

  &:before,
  &:after {
    color: #fff;
    content: '';
    display: block;
    position: absolute;
    border: .075em solid;
  }

  &:before {
    border-radius: inherit;
    height: .75em;
    transform: translate(.125em, .125em);
    width: .75em;
  }

  &:after {
    border-radius: 1em;
    box-shadow: .2em -.2em 0 -.16em;
    height: .4em;
    transform: translate(.3em, .3em);
    width: .4em;
  }
}

